var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-checkbox', {
    staticClass: "h3",
    attrs: {
      "hide-details": true,
      "label": "New purchase (Total = $".concat(_vm.total, ")")
    },
    on: {
      "change": _vm.togglerEdit
    },
    model: {
      value: _vm.togglerData,
      callback: function callback($$v) {
        _vm.togglerData = $$v;
      },
      expression: "togglerData"
    }
  }), _c('br'), _c('v-expand-transition', [_c('v-data-table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.togglerData,
      expression: "togglerData"
    }],
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.newPurchaseCopy,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.estimated_price",
      fn: function fn(props) {
        return [_c('v-edit-dialog', {
          attrs: {
            "return-value": props.item.estimated_price,
            "large": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(props.item, "estimated_price", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(props.item, "estimated_price", $event);
            },
            "save": _vm.saveData
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('div', {
                staticClass: "mt-4 text-h6"
              }, [_vm._v(" Estimated Purchase Price, $ ")]), _c('v-text-field', {
                attrs: {
                  "label": "Estimated Purchase Price, $",
                  "single-line": "",
                  "autofocus": "",
                  "disabled": _vm.FIELDS_DISABLED
                },
                on: {
                  "focus": _vm.estimatedNumberType,
                  "blur": _vm.estimatedTextType
                },
                model: {
                  value: props.item.estimated_price,
                  callback: function callback($$v) {
                    _vm.$set(props.item, "estimated_price", $$v);
                  },
                  expression: "props.item.estimated_price"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(_vm._s(props.item.estimated_price || 'Entry Value'))])])];
      }
    }, {
      key: "item.terms",
      fn: function fn(props) {
        return [_c('v-edit-dialog', {
          attrs: {
            "return-value": props.item.terms,
            "large": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(props.item, "terms", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(props.item, "terms", $event);
            },
            "save": _vm.saveData
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('div', {
                staticClass: "mt-4 text-h6"
              }, [_vm._v(" Term (years) ")]), _c('v-text-field', {
                attrs: {
                  "type": "number",
                  "label": "Term (years)",
                  "placeholder": "30",
                  "single-line": "",
                  "autofocus": "",
                  "disabled": _vm.FIELDS_DISABLED
                },
                model: {
                  value: props.item.terms,
                  callback: function callback($$v) {
                    _vm.$set(props.item, "terms", $$v);
                  },
                  expression: "props.item.terms"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(_vm._s(props.item.terms || 'Entry Value'))])])];
      }
    }, {
      key: "item.property_status",
      fn: function fn(props) {
        return [_c('v-edit-dialog', {
          attrs: {
            "return-value": props.item.property_status,
            "large": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(props.item, "property_status", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(props.item, "property_status", $event);
            },
            "save": _vm.saveData
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('div', {
                staticClass: "mt-4 text-h6"
              }, [_vm._v(" Property Status ")]), _c('v-select', {
                attrs: {
                  "items": _vm.loanSectionData.propertyStatusOptions,
                  "label": "Property status",
                  "disabled": _vm.FIELDS_DISABLED
                },
                model: {
                  value: props.item.property_status,
                  callback: function callback($$v) {
                    _vm.$set(props.item, "property_status", $$v);
                  },
                  expression: "props.item.property_status"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(_vm._s(props.item.property_status || 'Entry Value'))])])];
      }
    }, {
      key: "item.land_value",
      fn: function fn(props) {
        return [_c('v-edit-dialog', {
          attrs: {
            "return-value": props.item.land_value,
            "large": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(props.item, "land_value", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(props.item, "land_value", $event);
            },
            "save": _vm.saveData
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('div', {
                staticClass: "mt-4 text-h6"
              }, [_vm._v(" Land Value, $ ")]), _c('v-text-field', {
                attrs: {
                  "type": "number",
                  "label": "Land Value, $",
                  "placeholder": "30",
                  "single-line": "",
                  "autofocus": "",
                  "disabled": _vm.FIELDS_DISABLED
                },
                on: {
                  "focus": _vm.landValueNumberType,
                  "blur": _vm.landValueTextType
                },
                model: {
                  value: props.item.land_value,
                  callback: function callback($$v) {
                    _vm.$set(props.item, "land_value", $$v);
                  },
                  expression: "props.item.land_value"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(_vm._s(props.item.land_value || 'Entry Value'))])])];
      }
    }, {
      key: "item.costs",
      fn: function fn(props) {
        return [_c('v-edit-dialog', {
          attrs: {
            "return-value": props.item.costs,
            "large": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(props.item, "costs", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(props.item, "costs", $event);
            },
            "save": _vm.saveData
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('div', {
                staticClass: "mt-4 text-h6"
              }, [_vm._v(" Costs, $ ")]), _c('v-text-field', {
                attrs: {
                  "type": "number",
                  "label": "Costs, $",
                  "placeholder": "30",
                  "single-line": "",
                  "autofocus": "",
                  "disabled": _vm.FIELDS_DISABLED
                },
                on: {
                  "focus": _vm.costsNumberType,
                  "blur": _vm.costsTextType
                },
                model: {
                  value: props.item.costs,
                  callback: function callback($$v) {
                    _vm.$set(props.item, "costs", $$v);
                  },
                  expression: "props.item.costs"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(_vm._s(props.item.costs || 'Entry Value'))])])];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }