<template>
  <div>
    <v-checkbox
      class="h3"
      v-model="togglerData"
      :hide-details="true"
      :label="`Business Loan (Total = $${total})`"
      @change="togglerEdit"
    ></v-checkbox>
    <br>
    <v-expand-transition>
      <v-data-table
        v-show="togglerData"
        :headers="headers"
        :items="loanCopy"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:[`item.amount`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.amount"
            large
            @save="saveData"
          >
            <div>{{ props.item.amount || 'Entry Value' }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Amount, $
              </div>
              <v-text-field
                v-model="props.item.amount"
                label="Amount, $"
                @focus="estimatedNumberType"
                @blur="estimatedTextType"
                single-line
                autofocus
                :disabled="FIELDS_DISABLED"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import { loanSectionData } from '../dataOptions';

export default {
  name: 'NewPurchaseComponent',

  props: {
    loan: {
      type: Array,
      default: () => [],
    },

    toggler: {
      type: Boolean,
    },
  },

  data() {
    return {
      togglerData: null,

      loanSectionData,

      loanData: [],
      loanCopy: [],

      pagination: {},
      headers: [
        { text: 'Amount, $', value: 'amount', sortable: false },
        { text: 'Term (years)', value: 'terms', sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),

    total() {
      const value = this.loanData[0].amount;

      const total = this.$options.filters
        .integerFormatter(value);

      return total || 0;
    },
  },

  methods: {
    togglerEdit(event) {
      this.$emit('togglerChange', event);
    },

    estimatedTextType(event) {
      const target = event?.target;
      target.type = 'text';
      const textFormat = this.$options.filters.integerFormatter(this.loanCopy[0].amount);
      this.loanCopy[0].amount = textFormat;
    },

    estimatedNumberType(event) {
      const target = event?.target;
      target.type = 'number';

      const numberFormat = this.$options.filters.textFormatter(this.loanCopy[0].amount);
      this.loanCopy[0].amount = numberFormat;
    },

    saveData() {
      this.loanData = clone(this.loanCopy);

      const numberFormat = this.$options.filters.textFormatter(this.loanData[0].amount);
      this.loanData[0].amount = numberFormat;

      this.$emit('loanSave', this.loanData);
    },
  },

  created() {
    this.loanData = clone(this.loan);
    this.loanCopy = clone(this.loanData);

    this.togglerData = this.toggler;

    const textFormat = this.$options.filters.integerFormatter(this.loanCopy[0].amount);
    this.loanCopy[0].amount = textFormat;
  },

};
</script>
