<template>
  <div>
    <v-checkbox
      class="h3"
      v-model="togglerData"
      :hide-details="true"
      :label="`New purchase (Total = $${total})`"
      @change="togglerEdit"
    ></v-checkbox>
    <br>
    <v-expand-transition>
      <v-data-table
        v-show="togglerData"
        :headers="headers"
        :items="newPurchaseCopy"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:[`item.estimated_price`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.estimated_price"
            large
            @save="saveData"
            :disabled="FIELDS_DISABLED"
          >
            <div>{{ props.item.estimated_price || 'Entry Value' }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Estimated Purchase Price, $
              </div>
              <v-text-field
                v-model="props.item.estimated_price"
                label="Estimated Purchase Price, $"
                @focus="estimatedNumberType"
                @blur="estimatedTextType"
                single-line
                autofocus
                :disabled="FIELDS_DISABLED"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.terms`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.terms"
            large
            @save="saveData"
            :disabled="FIELDS_DISABLED"
          >
            <div>{{ props.item.terms || 'Entry Value' }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Term (years)
              </div>
              <v-text-field
                v-model="props.item.terms"
                type="number"
                label="Term (years)"
                placeholder="30"
                single-line
                autofocus
                :disabled="FIELDS_DISABLED"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.property_status`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.property_status"
            large
            @save="saveData"
            :disabled="FIELDS_DISABLED"
          >
            <div>{{ props.item.property_status || 'Entry Value' }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Property Status
              </div>
              <v-select
                v-model="props.item.property_status"
                :items="loanSectionData.propertyStatusOptions"
                label="Property status"
                :disabled="FIELDS_DISABLED"
              >
              </v-select>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.land_value`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.land_value"
            large
            @save="saveData"
            :disabled="FIELDS_DISABLED"
          >
            <div>{{ props.item.land_value || 'Entry Value' }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Land Value, $
              </div>
              <v-text-field
                v-model="props.item.land_value"
                type="number"
                label="Land Value, $"
                placeholder="30"
                @focus="landValueNumberType"
                @blur="landValueTextType"
                single-line
                autofocus
                :disabled="FIELDS_DISABLED"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.costs`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.costs"
            large
            @save="saveData"
            :disabled="FIELDS_DISABLED"
          >
            <div>{{ props.item.costs || 'Entry Value' }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Costs, $
              </div>
              <v-text-field
                v-model="props.item.costs"
                type="number"
                label="Costs, $"
                placeholder="30"
                @focus="costsNumberType"
                @blur="costsTextType"
                single-line
                autofocus
                :disabled="FIELDS_DISABLED"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import { loanSectionData } from '../dataOptions';

export default {
  name: 'NewPurchaseComponent',

  props: {
    newPurchase: {
      type: Array,
      default: () => [],
    },

    toggler: {
      type: Boolean,
    },
  },

  data() {
    return {
      togglerData: null,

      loanSectionData,

      newPurchaseData: [],
      newPurchaseCopy: [],

      headers: [],

      headersShort: [
        { text: 'Estimated Purchase Price, $', value: 'estimated_price', sortable: false },
        { text: 'Term (years)', value: 'terms', sortable: false },
        { text: 'Property Status', value: 'property_status', sortable: false },
      ],

      headersExtend: [
        { text: 'Estimated Purchase Price, $', value: 'estimated_price', sortable: false },
        { text: 'Term (years)', value: 'terms', sortable: false },
        { text: 'Property Status', value: 'property_status', sortable: false },
        { text: 'Land Value, $', value: 'land_value', sortable: false },
        { text: 'Costs, $', value: 'costs', sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),

    statusChecker() {
      return this.newPurchaseData[0]?.property_status;
    },

    total() {
      const value = this.newPurchaseData[0].estimated_price;

      const total = this.$options.filters
        .integerFormatter(value);

      return total || 0;
    },
  },

  methods: {
    togglerEdit(event) {
      this.$emit('togglerChange', event);
    },

    estimatedTextType(event) {
      const target = event?.target;
      target.type = 'text';
      const textFormat = this.$options.filters
        .integerFormatter(this.newPurchaseCopy[0].estimated_price);
      this.newPurchaseCopy[0].estimated_price = textFormat;
    },

    estimatedNumberType(event) {
      const target = event?.target;
      target.type = 'number';

      const numberFormat = this.$options.filters
        .textFormatter(this.newPurchaseCopy[0].estimated_price);
      this.newPurchaseCopy[0].estimated_price = numberFormat;
    },

    landValueTextType(event) {
      const target = event?.target;
      target.type = 'text';
      const textFormat = this.$options.filters.integerFormatter(this.newPurchaseCopy[0].land_value);
      this.newPurchaseCopy[0].land_value = textFormat;
    },

    landValueNumberType(event) {
      const target = event?.target;
      target.type = 'number';

      const numberFormat = this.$options.filters.textFormatter(this.newPurchaseCopy[0].land_value);
      this.newPurchaseCopy[0].land_value = numberFormat;
    },

    costsTextType(event) {
      const target = event?.target;
      target.type = 'text';
      const textFormat = this.$options.filters.integerFormatter(this.newPurchaseCopy[0].costs);
      this.newPurchaseCopy[0].costs = textFormat;
    },

    costsNumberType(event) {
      const target = event?.target;
      target.type = 'number';

      const numberFormat = this.$options.filters.textFormatter(this.newPurchaseCopy[0].costs);
      this.newPurchaseCopy[0].costs = numberFormat;
    },

    saveData() {
      this.newPurchaseData = clone(this.newPurchaseCopy);

      const estimateNumberFormat = this.$options.filters
        .textFormatter(this.newPurchaseData[0].estimated_price);
      this.newPurchaseData[0].estimated_price = estimateNumberFormat;

      const landValueNumberFormat = this.$options.filters
        .textFormatter(this.newPurchaseData[0].land_value);
      this.newPurchaseData[0].land_value = landValueNumberFormat;

      const costsNumberFormat = this.$options.filters.textFormatter(this.newPurchaseData[0].costs);
      this.newPurchaseData[0].costs = costsNumberFormat;

      this.$emit('newPurchaseSave', this.newPurchaseData);
    },
  },

  created() {
    this.newPurchaseData = clone(this.newPurchase);
    this.newPurchaseCopy = clone(this.newPurchaseData);

    this.togglerData = this.toggler;

    const estimateTextFormat = this.$options.filters
      .integerFormatter(this.newPurchaseCopy[0].estimated_price);
    this.newPurchaseCopy[0].estimated_price = estimateTextFormat;

    const landValueTextFormat = this.$options.filters
      .integerFormatter(this.newPurchaseCopy[0].land_value);
    this.newPurchaseCopy[0].land_value = landValueTextFormat;

    const costsTextFormat = this.$options.filters.integerFormatter(this.newPurchaseCopy[0].costs);
    this.newPurchaseCopy[0].costs = costsTextFormat;
  },

  watch: {
    statusChecker(value) {
      if (value === 'construction') {
        this.headers = this.headersExtend;
      } else {
        this.headers = this.headersShort;
      }
    },
  },

};
</script>
