<template>
  <div>
    <v-checkbox
      class="h3"
      v-model="togglerData"
      :hide-details="true"
      :label="`Consolidate debt (Total = $${getLiabilitiesSummary})`"
      @change="togglerEdit"
    ></v-checkbox>
    <br>
    <v-expand-transition>
      <v-data-table
        v-show="togglerData"
        :headers="headers"
        :items="debtCopy"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:[`item.adjustment`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.adjustment"
            large
            @save="saveData"
          >
            <div>{{ props.item.adjustment || 'Entry Value' }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Amount adjustment, $
              </div>
              <v-text-field
                v-model="props.item.adjustment"
                label="Amount adjustment, $"
                @focus="adjustmentNumberType"
                @blur="adjustmentTextType"
                single-line
                autofocus
                :disabled="FIELDS_DISABLED"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.comment`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.comment"
            large
            @save="saveData"
          >
            <div>{{ props.item.comment || 'Entry Value' }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Comment
              </div>
              <v-text-field
                v-model="props.item.comment"
                label="Comment"
                placeholder="Comment"
                single-line
                autofocus
                :disabled="FIELDS_DISABLED"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SummaryMixin from '@/mixins/SummaryMixin';

export default {
  name: 'ConsoliadteDebtComponent',

  mixins: [SummaryMixin],

  props: {
    debt: {
      type: Array,
      default: () => [],
    },

    toggler: {
      type: Boolean,
    },
  },

  data() {
    return {
      togglerData: null,

      debtData: [],
      debtCopy: [],

      headers: [
        { text: 'Amount, $', value: 'amount', sortable: false },
        { text: 'Amount adjustment, $', value: 'adjustment', sortable: false },
        { text: 'Term', value: 'term', sortable: false },
        { text: 'Comment', value: 'comment', sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),
  },

  methods: {
    togglerEdit(event) {
      this.$emit('togglerChange', event);
    },

    adjustmentTextType(event) {
      const target = event?.target;
      target.type = 'text';
      const textFormat = this.$options.filters.integerFormatter(this.debtCopy[0].adjustment);
      this.debtCopy[0].adjustment = textFormat;
    },

    adjustmentNumberType(event) {
      const target = event?.target;
      target.type = 'number';

      const numberFormat = this.$options.filters.textFormatter(this.debtCopy[0].adjustment);
      this.debtCopy[0].adjustment = numberFormat;
    },

    saveData() {
      this.debtData = clone(this.debtCopy);

      const amount = this.debtData?.[0]?.amount;
      const amountNumberFormat = this.$options.filters.textFormatter(amount);
      this.debtData[0].amount = amountNumberFormat;

      const adjustment = this.debtData?.[0]?.adjustment;
      const adjustmentNumberFormat = this.$options.filters.textFormatter(adjustment);
      this.debtData[0].adjustment = adjustmentNumberFormat;

      this.$emit('debtSave', this.debtData);
    },
  },

  created() {
    this.debtData = clone(this.debt);
    this.debtCopy = clone(this.debtData);

    this.togglerData = this.toggler;

    this.debtCopy[0].amount = this.getLiabilitiesSummary;

    const amount = this.debtCopy?.[0]?.amount;
    const amountTextFormat = this.$options.filters.integerFormatter(amount);
    this.debtCopy[0].amount = amountTextFormat;

    const adjustment = this.debtCopy?.[0]?.adjustment;
    const adjustmentTextFormat = this.$options.filters.integerFormatter(adjustment);
    this.debtCopy[0].adjustment = adjustmentTextFormat;
  },

  watch: {
    'APPLICATION.data.liabilities.items': {
      handler() {
        this.debtCopy[0].amount = this.getLiabilitiesSummary;
      },

      deep: true,
    },
  },

};
</script>
