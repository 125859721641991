var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        var _vm$loan, _vm$loan2, _vm$loan3, _vm$loan3$toggle, _vm$loan4, _vm$loan5, _vm$loan6, _vm$loan6$toggle, _vm$loan7, _vm$loan7$toggle, _vm$loan8, _vm$loan9, _vm$loan10, _vm$loan10$toggle, _vm$loan11, _vm$loan12, _vm$loan13, _vm$loan13$toggle, _vm$loan14, _vm$loan15, _vm$loan16, _vm$loan16$toggle;

        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [(_vm$loan = _vm.loan) !== null && _vm$loan !== void 0 && _vm$loan.new_purchase ? _c('NewPurchaseComponent', {
          attrs: {
            "newPurchase": (_vm$loan2 = _vm.loan) === null || _vm$loan2 === void 0 ? void 0 : _vm$loan2.new_purchase,
            "toggler": (_vm$loan3 = _vm.loan) === null || _vm$loan3 === void 0 ? void 0 : (_vm$loan3$toggle = _vm$loan3.toggle) === null || _vm$loan3$toggle === void 0 ? void 0 : _vm$loan3$toggle.newPurсhase
          },
          on: {
            "newPurchaseSave": _vm.newPurchaseSave,
            "togglerChange": _vm.newPurchaseTogglerChange
          }
        }) : _vm._e(), (_vm$loan4 = _vm.loan) !== null && _vm$loan4 !== void 0 && _vm$loan4.debt ? _c('ConsoliadteDebtComponent', {
          attrs: {
            "debt": (_vm$loan5 = _vm.loan) === null || _vm$loan5 === void 0 ? void 0 : _vm$loan5.debt,
            "toggler": (_vm$loan6 = _vm.loan) === null || _vm$loan6 === void 0 ? void 0 : (_vm$loan6$toggle = _vm$loan6.toggle) === null || _vm$loan6$toggle === void 0 ? void 0 : _vm$loan6$toggle.debt
          },
          on: {
            "debtSave": _vm.debtSave,
            "togglerChange": _vm.debtTogglerChange
          }
        }) : _vm._e(), _c('RefinanceComponent', {
          key: _vm.refinanceKey,
          attrs: {
            "toggler": (_vm$loan7 = _vm.loan) === null || _vm$loan7 === void 0 ? void 0 : (_vm$loan7$toggle = _vm$loan7.toggle) === null || _vm$loan7$toggle === void 0 ? void 0 : _vm$loan7$toggle.refinance
          },
          on: {
            "refinanceSave": _vm.refinanceSave,
            "togglerChange": _vm.refinanceTogglerChange
          }
        }), (_vm$loan8 = _vm.loan) !== null && _vm$loan8 !== void 0 && _vm$loan8.topUp ? _c('TopUpComponent', {
          attrs: {
            "topUp": (_vm$loan9 = _vm.loan) === null || _vm$loan9 === void 0 ? void 0 : _vm$loan9.topUp,
            "toggler": (_vm$loan10 = _vm.loan) === null || _vm$loan10 === void 0 ? void 0 : (_vm$loan10$toggle = _vm$loan10.toggle) === null || _vm$loan10$toggle === void 0 ? void 0 : _vm$loan10$toggle.topUp
          },
          on: {
            "changeTopUp": _vm.changeTopUp,
            "togglerChange": _vm.topUpTogglerChange
          }
        }) : _vm._e(), (_vm$loan11 = _vm.loan) !== null && _vm$loan11 !== void 0 && _vm$loan11.loan ? _c('LoanComponent', {
          attrs: {
            "loan": (_vm$loan12 = _vm.loan) === null || _vm$loan12 === void 0 ? void 0 : _vm$loan12.loan,
            "toggler": (_vm$loan13 = _vm.loan) === null || _vm$loan13 === void 0 ? void 0 : (_vm$loan13$toggle = _vm$loan13.toggle) === null || _vm$loan13$toggle === void 0 ? void 0 : _vm$loan13$toggle.loan
          },
          on: {
            "loanSave": _vm.loanSave,
            "togglerChange": _vm.loanTogglerChange
          }
        }) : _vm._e(), (_vm$loan14 = _vm.loan) !== null && _vm$loan14 !== void 0 && _vm$loan14.deposit_source ? _c('DepositComponent', {
          attrs: {
            "deposits": (_vm$loan15 = _vm.loan) === null || _vm$loan15 === void 0 ? void 0 : _vm$loan15.deposit_source,
            "toggler": (_vm$loan16 = _vm.loan) === null || _vm$loan16 === void 0 ? void 0 : (_vm$loan16$toggle = _vm$loan16.toggle) === null || _vm$loan16$toggle === void 0 ? void 0 : _vm$loan16$toggle.deposit
          },
          on: {
            "onUpdateDeposits": _vm.updateDeposits,
            "togglerChange": _vm.depositTogglerChange
          }
        }) : _vm._e(), _c('br'), _c('v-simple-table', {
          staticClass: "no-hover",
          attrs: {
            "dense": ""
          }
        }, [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "no-padding",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Lending Required")]), _c('td', {
          staticClass: "no-padding"
        }, [_vm._v("$" + _vm._s(_vm.lendingRequired))])])])])], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }