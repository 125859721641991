var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-checkbox', {
    staticClass: "h3",
    attrs: {
      "hide-details": true,
      "label": "Consolidate debt (Total = $".concat(_vm.getLiabilitiesSummary, ")")
    },
    on: {
      "change": _vm.togglerEdit
    },
    model: {
      value: _vm.togglerData,
      callback: function callback($$v) {
        _vm.togglerData = $$v;
      },
      expression: "togglerData"
    }
  }), _c('br'), _c('v-expand-transition', [_c('v-data-table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.togglerData,
      expression: "togglerData"
    }],
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.debtCopy,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.adjustment",
      fn: function fn(props) {
        return [_c('v-edit-dialog', {
          attrs: {
            "return-value": props.item.adjustment,
            "large": ""
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(props.item, "adjustment", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(props.item, "adjustment", $event);
            },
            "save": _vm.saveData
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('div', {
                staticClass: "mt-4 text-h6"
              }, [_vm._v(" Amount adjustment, $ ")]), _c('v-text-field', {
                attrs: {
                  "label": "Amount adjustment, $",
                  "single-line": "",
                  "autofocus": "",
                  "disabled": _vm.FIELDS_DISABLED
                },
                on: {
                  "focus": _vm.adjustmentNumberType,
                  "blur": _vm.adjustmentTextType
                },
                model: {
                  value: props.item.adjustment,
                  callback: function callback($$v) {
                    _vm.$set(props.item, "adjustment", $$v);
                  },
                  expression: "props.item.adjustment"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(_vm._s(props.item.adjustment || 'Entry Value'))])])];
      }
    }, {
      key: "item.comment",
      fn: function fn(props) {
        return [_c('v-edit-dialog', {
          attrs: {
            "return-value": props.item.comment,
            "large": ""
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(props.item, "comment", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(props.item, "comment", $event);
            },
            "save": _vm.saveData
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('div', {
                staticClass: "mt-4 text-h6"
              }, [_vm._v(" Comment ")]), _c('v-text-field', {
                attrs: {
                  "label": "Comment",
                  "placeholder": "Comment",
                  "single-line": "",
                  "autofocus": "",
                  "disabled": _vm.FIELDS_DISABLED
                },
                model: {
                  value: props.item.comment,
                  callback: function callback($$v) {
                    _vm.$set(props.item, "comment", $$v);
                  },
                  expression: "props.item.comment"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(_vm._s(props.item.comment || 'Entry Value'))])])];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }