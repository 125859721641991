<template>
  <div>
    <v-checkbox
      class="h3"
      v-model="togglerData"
      :hide-details="true"
      :label="`Deposit source (Total = $${total})`"
      @change="togglerEdit"
    ></v-checkbox>
    <br>
    <v-expand-transition>
      <v-data-table
        v-show="togglerData"
        :headers="headers"
        :items="depositsCopy"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <br>
          <v-toolbar
            flat
          >
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="resetValues"
                  :disabled="FIELDS_DISABLED"
                >
                  Add New
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-combobox
                          v-model="editedItem.source"
                          :items="depositSourceOptions"
                          label="Source"
                          placeholder="Source"
                          dense
                          :disabled="FIELDS_DISABLED"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          dense
                          type="text"
                          v-model="amountValue"
                          @focus="amountNumberType"
                          @blur="amountTextType"
                          placeholder="Amount, $"
                          label="Amount, $"
                          :disabled="FIELDS_DISABLED"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="saveData"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import { depositSourceOptions } from '../dataOptions';

export default {
  name: 'TopUpComponent',

  emits: ['onUpdateDeposits'],

  props: {
    deposits: {
      type: Array,
      default: () => {},
    },

    toggler: {
      type: Boolean,
    },
  },

  data() {
    return {
      togglerData: null,

      depositSourceOptions,

      depositsData: [],
      depositsCopy: [],

      amountValue: '',

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Source', value: 'source', sortable: false },
        { text: 'Amount, $', value: 'amount', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        amount: '',
        term: '',
      },
      defaultItem: {
        amount: '',
        term: '',
      },
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),

    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },

    total() {
      if (this.depositsData.length > 0) {
        let total = 0;

        this.depositsData.forEach((item) => {
          total += parseInt(item.amount, 10) || 0;
        });

        return total.toLocaleString();
      }

      return 0;
    },
  },

  methods: {
    togglerEdit(event) {
      this.$emit('togglerChange', event);
    },

    resetValues() {
      this.amountValue = '';
    },

    textFieldsFormat() {
      this.depositsCopy.forEach((item, index) => {
        this.depositsData[index].amount = this.$options.filters.textFormatter(item.amount);
      });
    },

    numberFieldsFormat() {
      this.depositsData.forEach((item, index) => {
        this.depositsCopy[index].amount = this.$options.filters.integerFormatter(item.amount);
      });
    },

    amountTextType(event) {
      const target = event?.target;
      target.type = 'text';
      this.amountValue = this.$options.filters.integerFormatter(this.amountValue);
      this.editedItem.amount = this.$options.filters.textFormatter(this.amountValue);
    },

    amountNumberType(event) {
      const target = event?.target;
      target.type = 'number';

      this.amountValue = this.$options.filters.textFormatter(this.amountValue);
    },

    editItem(item) {
      this.editedIndex = this.depositsCopy.indexOf(item);
      this.editedItem = { ...item };
      this.amountValue = this.$options.filters.integerFormatter(this.editedItem.amount);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.depositsCopy.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.depositsCopy.splice(this.editedIndex, 1);
      this.depositsData.splice(this.editedIndex, 1);
      this.$emit('onUpdateDeposits', this.depositsData);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    saveData() {
      if (this.editedIndex > -1) {
        Object.assign(this.depositsCopy[this.editedIndex], this.editedItem);
      } else {
        this.depositsCopy.push(this.editedItem);
      }

      this.depositsData = clone(this.depositsCopy);
      this.textFieldsFormat();
      this.numberFieldsFormat();

      this.$emit('onUpdateDeposits', this.depositsData);
      this.close();
    },
  },

  created() {
    this.depositsData = clone(this.deposits);
    this.depositsCopy = clone(this.depositsData);

    this.togglerData = this.toggler;

    this.numberFieldsFormat();
  },
};
</script>
