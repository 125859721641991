var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-checkbox', {
    staticClass: "h3",
    attrs: {
      "hide-details": true,
      "label": "Business Loan (Total = $".concat(_vm.total, ")")
    },
    on: {
      "change": _vm.togglerEdit
    },
    model: {
      value: _vm.togglerData,
      callback: function callback($$v) {
        _vm.togglerData = $$v;
      },
      expression: "togglerData"
    }
  }), _c('br'), _c('v-expand-transition', [_c('v-data-table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.togglerData,
      expression: "togglerData"
    }],
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.loanCopy,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.amount",
      fn: function fn(props) {
        return [_c('v-edit-dialog', {
          attrs: {
            "return-value": props.item.amount,
            "large": ""
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(props.item, "amount", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(props.item, "amount", $event);
            },
            "save": _vm.saveData
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('div', {
                staticClass: "mt-4 text-h6"
              }, [_vm._v(" Amount, $ ")]), _c('v-text-field', {
                attrs: {
                  "label": "Amount, $",
                  "single-line": "",
                  "autofocus": "",
                  "disabled": _vm.FIELDS_DISABLED
                },
                on: {
                  "focus": _vm.estimatedNumberType,
                  "blur": _vm.estimatedTextType
                },
                model: {
                  value: props.item.amount,
                  callback: function callback($$v) {
                    _vm.$set(props.item, "amount", $$v);
                  },
                  expression: "props.item.amount"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(_vm._s(props.item.amount || 'Entry Value'))])])];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }