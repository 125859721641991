<template>
  <div>
    <v-checkbox
      class="h3"
      v-model="togglerData"
      :hide-details="true"
      :label="`Refinance (Total = $${total})`"
      @change="togglerEdit"
    ></v-checkbox>
    <br>
    <v-expand-transition>
      <v-data-table
        v-show="togglerData"
        :headers="headers"
        :items="refinanceData"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:[`item.term`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.term"
            large
            @save="saveData"
          >
            <div>{{ props.item.term || 'Entry Value' }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Term, years
              </div>
              <v-text-field
                v-model="props.item.term"
                label="Term, years"
                type="number"
                single-line
                autofocus
                :disabled="FIELDS_DISABLED"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RefinanceComponent',

  props: {
    toggler: {
      type: Boolean,
    },
  },

  data() {
    return {
      togglerData: null,

      headers: [
        { text: 'Amount, $', value: 'balance', sortable: false },
        { text: 'Term, years', value: 'term', sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters(['APPLICATION', 'FIELDS_DISABLED']),

    refinanceExist() {
      const refianance = this.APPLICATION?.data?.liabilities?.mortgages.filter((item) => item.refianance === 'Refinance');
      return refianance;
    },

    refinanceData() {
      const LFDTerms = this.APPLICATION?.data?.funding_details?.debt?.[0]?.term;

      const newData = this.refinanceExist.map((item) => {
        const balance = this.$options.filters.integerFormatter(item.balance);

        const newItem = {
          balance,
          term: item.term || LFDTerms,
          id: item.id,
        };

        return newItem;
      });

      return newData;
    },

    total() {
      let total = 0;

      this.refinanceData.forEach((item) => {
        const value = this.$options.filters.textFormatter(item.balance);
        total += parseInt(value, 10);
      });

      const totalFormatted = this.$options.filters.integerFormatter(total.toString());

      return totalFormatted;
    },
  },

  methods: {
    togglerEdit(event) {
      this.$emit('togglerChange', event);
    },

    saveData() {
      this.$emit('refinanceSave', this.refinanceData);
    },
  },

  created() {
    this.togglerData = this.toggler;
  },
};
</script>
