var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-checkbox', {
    staticClass: "h3",
    attrs: {
      "hide-details": true,
      "label": "Top up (Total = $".concat(_vm.total, ")")
    },
    on: {
      "change": _vm.togglerEdit
    },
    model: {
      value: _vm.togglerData,
      callback: function callback($$v) {
        _vm.togglerData = $$v;
      },
      expression: "togglerData"
    }
  }), _c('br'), _c('v-expand-transition', [_c('v-data-table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.togglerData,
      expression: "togglerData"
    }],
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.topUpCopy,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('br'), _c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-dialog', {
          attrs: {
            "max-width": "500px"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mb-2",
                attrs: {
                  "color": "primary",
                  "disabled": _vm.FIELDS_DISABLED
                },
                on: {
                  "click": _vm.resetValues
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" Add New ")])];
            }
          }]),
          model: {
            value: _vm.dialog,
            callback: function callback($$v) {
              _vm.dialog = $$v;
            },
            expression: "dialog"
          }
        }, [_c('v-card', [_c('v-card-title', [_c('span', {
          staticClass: "text-h5"
        }, [_vm._v(_vm._s(_vm.formTitle))])]), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('v-text-field', {
          attrs: {
            "dense": "",
            "type": "text",
            "placeholder": "Amount, $",
            "label": "Amount, $",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "focus": _vm.amountNumberType,
            "blur": _vm.amountTextType
          },
          model: {
            value: _vm.amountValue,
            callback: function callback($$v) {
              _vm.amountValue = $$v;
            },
            expression: "amountValue"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('v-text-field', {
          attrs: {
            "dense": "",
            "type": "number",
            "placeholder": "Term, years",
            "label": "Term, years",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.editedItem.term,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "term", $$v);
            },
            expression: "editedItem.term"
          }
        })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": _vm.saveData
          }
        }, [_vm._v(" Save ")])], 1)], 1)], 1), _c('v-dialog', {
          attrs: {
            "max-width": "500px"
          },
          model: {
            value: _vm.dialogDelete,
            callback: function callback($$v) {
              _vm.dialogDelete = $$v;
            },
            expression: "dialogDelete"
          }
        }, [_c('v-card', [_c('v-card-title', {
          staticClass: "text-h5"
        }, [_vm._v(" Are you sure you want to delete this item? ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": _vm.closeDelete
          }
        }, [_vm._v("Cancel")]), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": _vm.deleteItemConfirm
          }
        }, [_vm._v("OK")]), _c('v-spacer')], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(item);
            }
          }
        }, [_vm._v(" mdi-pencil ")]), _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(item);
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }