<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <NewPurchaseComponent
            v-if="loan?.new_purchase"
            :newPurchase="loan?.new_purchase"
            :toggler="loan?.toggle?.newPurсhase"
            @newPurchaseSave="newPurchaseSave"
            @togglerChange="newPurchaseTogglerChange"
          >
          </NewPurchaseComponent>
          <ConsoliadteDebtComponent
            v-if="loan?.debt"
            :debt="loan?.debt"
            :toggler="loan?.toggle?.debt"
            @debtSave="debtSave"
            @togglerChange="debtTogglerChange"
          >
          </ConsoliadteDebtComponent>
          <RefinanceComponent
            :key="refinanceKey"
            :toggler="loan?.toggle?.refinance"
            @refinanceSave="refinanceSave"
            @togglerChange="refinanceTogglerChange"
          >
          </RefinanceComponent>
          <TopUpComponent
            v-if="loan?.topUp"
            :topUp="loan?.topUp"
            :toggler="loan?.toggle?.topUp"
            @changeTopUp="changeTopUp"
            @togglerChange="topUpTogglerChange"
          >
          </TopUpComponent>
          <LoanComponent
            v-if="loan?.loan"
            :loan="loan?.loan"
            :toggler="loan?.toggle?.loan"
            @loanSave="loanSave"
            @togglerChange="loanTogglerChange"
          >
          </LoanComponent>
          <DepositComponent
            v-if="loan?.deposit_source"
            :deposits="loan?.deposit_source"
            :toggler="loan?.toggle?.deposit"
            @onUpdateDeposits="updateDeposits"
            @togglerChange="depositTogglerChange"
          >
          </DepositComponent>
          <br>
          <v-simple-table
            class='no-hover'
            dense
          >
            <tbody>
              <tr>
                <td width='20%' class="no-padding">Lending Required</td>
                <td class="no-padding">${{ lendingRequired }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import Vue from 'vue';

import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';
import SummaryMixins from '@/mixins/SummaryMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';
import NewPurchaseComponent from '@/components/ApplicationSections/Components/NewPurchaseComponent.vue';
import ConsoliadteDebtComponent from '@/components/ApplicationSections/Components/ConsoliadteDebtComponent.vue';
import RefinanceComponent from '@/components/ApplicationSections/Components/RefinanceComponent.vue';
import TopUpComponent from '@/components/ApplicationSections/Components/TopUpComponent.vue';
import LoanComponent from '@/components/ApplicationSections/Components/LoanComponent.vue';
import DepositComponent from '@/components/ApplicationSections/Components/DepositComponent.vue';

export default {
  name: 'LoanSection',

  components: {
    BaseSection,
    NewPurchaseComponent,
    ConsoliadteDebtComponent,
    RefinanceComponent,
    TopUpComponent,
    LoanComponent,
    DepositComponent,
  },

  mixins: [SectionMixin, SummaryMixins],

  props: {
    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      addressOptions: [],
      refinanceKey: false,

      isSectionVisible: false,
      created: false,

      initialLoan: {},

      loan: {},
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED', 'APPLICATION']),
  },

  async created() {
    await this.checkIsSectionVisible();
    this.initialLoan = clone(this.existedData);
    this.loan = clone(this.existedData);

    this.$emit('componentReady');
  },

  methods: {
    newPurchaseTogglerChange(data) {
      this.loan.toggle.newPurсhase = data;
    },

    debtTogglerChange(data) {
      this.loan.toggle.debt = data;
    },

    refinanceTogglerChange(data) {
      this.loan.toggle.refinance = data;
    },

    topUpTogglerChange(data) {
      this.loan.toggle.topUp = data;
    },

    loanTogglerChange(data) {
      this.loan.toggle.loan = data;
    },

    depositTogglerChange(data) {
      this.loan.toggle.deposit = data;
    },

    newPurchaseSave(data) {
      this.loan.new_purchase = data;
    },

    debtSave(data) {
      this.loan.debt = data;
    },

    changeTopUp(data) {
      Vue.set(this.loan, 'topUp', data);
    },

    refinanceSave(data) {
      Vue.set(this.loan, 'refinanceEditCheck', data);
    },

    loanSave(data) {
      this.loan.loan = data;
    },

    updateDeposits(deposits) {
      Vue.set(this.loan, 'deposit_source', deposits);
    },
  },

  watch: {
    'APPLICATION.data.funding_details.refinanceEditCheck': {
      async handler(newValue, oldValue) {
        if (oldValue !== undefined && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const liabilitiesCopy = clone(this.APPLICATION?.data?.liabilities);
          const mortgagesCopy = clone(this.APPLICATION?.data?.liabilities?.mortgages);

          const newData = [];

          newValue.forEach((item) => {
            mortgagesCopy.forEach((mortgage, index) => {
              newData[index] = mortgage;

              if (mortgage.id === item.id) {
                newData[index].term = item.term;
              }
            });
          });

          liabilitiesCopy.mortgages = newData;

          const payment = {
            id: '',
            sectionKey: 'liabilities',
            payload: liabilitiesCopy,
            subApplicant: false,
          };

          await this.$store.dispatch('storeSectionData', payment);
          this.refinanceKey = !this.refinanceKey;
        }
      },
    },

    lendingRequired: {
      async handler(value) {
        const editedApp = this.APPLICATION?.data?.summary;

        if (editedApp.lending_required !== value) {
          editedApp.lending_required = value;

          const payment = {
            id: '',
            sectionKey: 'summary',
            payload: editedApp,
            subApplicant: false,
          };

          await this.$store.dispatch('storeSectionData', payment);
        }
      },
      immediate: false,
    },
  },
};
</script>
